import React, { FC, useState } from 'react';
import { Group } from '@app/core/models';
import {
  Box,
  Button,
  Dialog,
  DialogProps,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import {
  useCreateGroupMutation,
  useDeleteGroupMutation,
  useUpdateGroupMutation,
} from '@app/core/store/groups';
import * as yup from 'yup';
import { ConfirmationModal } from '../ConfirmationModal';
import { useGetGroupKindsListQuery } from '@app/core/store/group_kinds';
import { LocalSelectV2 } from '../LocalSelectV2/LocalSelectV2';
import { hideNumberControls } from '@app/constants/sx';
// import { ImagePickerV2 } from '../ImagePickerV2/ImagePickerV2';
import { omitEmpty } from '@app/utils/omitEmpty';
import { LocalCheckbox } from '../LocalCheckbox/LocalCheckbox';

const getRuGroupKindName = (name: string) => {
  return {
    [name]: name,
    HOTEL: 'Отели',
    TRANSFER: 'Транспорт',
    ATTRACTION: 'Достопримечательности',
    PLACE: 'Места',
    FOOD: 'Еда',
    PERSON: 'Гиды',
    MAP: 'Карта',
  }[name];
};

const validationSchema = yup.object().shape({
  name: yup.string().required('Введите название'),
  kind: yup.string().required('Укажите тип'),
});

interface FormikData {
  name: string;
  icon: string | null;
  position: string;
  kind: string;
  isUserCanContribute: boolean;
}

interface GroupDialogProps extends DialogProps {
  group?: Group;
  onSave?: () => void;
}

export const GroupDialog: FC<GroupDialogProps> = ({
  group,
  onClose,
  onSave,
  ...dialogProps
}) => {
  const [confirm, setConfirm] = useState(false);
  // const [preview, setPreview] = useState(group?.icon || '');

  const [updateGroup] = useUpdateGroupMutation();
  const [createGroup] = useCreateGroupMutation();
  const [deleteGroup] = useDeleteGroupMutation();

  const groupKinsApi = useGetGroupKindsListQuery({});
  const groupKinds = groupKinsApi.data?.data.results ?? [];

  const submitApi = async (values: FormikData, id?: Group['id']) => {
    const preparedData = omitEmpty(values, 'position');
    if (id) {
      try {
        await updateGroup({ ...preparedData, id }).unwrap();
        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    } else {
      try {
        await createGroup(preparedData).unwrap();
        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    }
  };

  const handleFormikSubmit = async (values: FormikData) => {
    const isSubmitted = await submitApi(values, group?.id);
    if (isSubmitted) {
      onSave?.();
      onClose?.({}, 'backdropClick');
    }
  };

  const { values, handleChange, handleSubmit, dirty, isValid } =
    useFormik<FormikData>({
      validationSchema,
      onSubmit: handleFormikSubmit,
      initialValues: {
        name: group?.name ?? '',
        icon: group?.icon || null,
        position: group?.position?.toString() ?? '',
        kind: group?.kindId.toString() ?? '',
        isUserCanContribute: group?.isUserCanContribute ?? false,
      },
    });

  // const handleAddImage = async (files: File[]) => {
  //   if (!files.length) return;
  //   const [file] = files;
  //   setFieldValue('icon', file);
  //   const url = URL.createObjectURL(file);
  //   setPreview(url);
  // };

  const handleCancelClick = () => {
    onClose?.({}, 'backdropClick');
  };

  const handleAcceptClick = () => {
    handleSubmit();
  };

  const handleDeleteClick = () => {
    setConfirm(true);
  };

  const handleConfirm = async () => {
    if (!group) return;
    try {
      await deleteGroup({ id: group.id }).unwrap();
      onClose?.({}, 'backdropClick');
    } catch (error) {
      console.error(error);
      setConfirm(false);
    }
  };

  return (
    <Dialog
      onClose={onClose}
      PaperProps={{
        sx: {
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: '20px',
          alignItems: 'flex-start',
          gap: '16px',
        },
      }}
      {...dialogProps}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '0 0 16px 0',
          width: '100%',
        }}
      >
        <Typography sx={{ fontSize: '30px' }}>
          {group ? group.name : 'Новая группа'}
        </Typography>
        {group ? (
          <Button color="error" onClick={handleDeleteClick}>
            Удалить
          </Button>
        ) : null}
      </Box>
      {/* <ImagePickerV2
        onDrop={handleAddImage}
        imagePreview={preview}
        label="Иконка"
        sx={{ width: '100%' }}
      /> */}
      <TextField
        label="Название *"
        name="name"
        value={values.name}
        onChange={handleChange}
        fullWidth
      />
      <LocalSelectV2
        label="Тип *"
        value={values.kind}
        onChange={handleChange}
        inputProps={{ name: 'kind' }}
        sx={{ width: '100%' }}
      >
        {groupKinds.map(gk => (
          <MenuItem key={gk.id} value={gk.id.toString()}>
            {getRuGroupKindName(gk.name)}
          </MenuItem>
        ))}
      </LocalSelectV2>
      <TextField
        label="Позиция"
        name="position"
        type="number"
        sx={hideNumberControls}
        value={values.position}
        onChange={handleChange}
        fullWidth
      />

      <LocalCheckbox
        name="isUserCanContribute"
        slotProps={{
          checkbox: {
            checked: values.isUserCanContribute,
            onChange: handleChange,
          },
          labelSpan: { children: 'Доступна для пользователей' },
        }}
      />

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '16px 0 0 0',
          width: '100%',
        }}
      >
        <Button variant="contained" onClick={handleCancelClick}>
          {group ? 'Закрыть без изменений' : 'Отмена'}
        </Button>
        <Button
          onClick={handleAcceptClick}
          disabled={group ? !dirty || !isValid : !isValid}
        >
          {group ? 'Сохранить изменения' : 'Создать'}
        </Button>
      </Box>
      <ConfirmationModal
        open={confirm}
        onConfirm={handleConfirm}
        onClose={() => setConfirm(false)}
      />
    </Dialog>
  );
};
