import React from 'react';

export const MagnifyingGlassIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({
  color = '#F5F6FA',
  ...props
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.351 17.5629C11.9474 17.5629 13.4195 17.0516 14.624 16.1994L19.1551 20.6875C19.3654 20.8958 19.6426 21 19.9389 21C20.5603 21 21 20.5266 21 19.9206C21 19.6365 20.9044 19.3619 20.6941 19.1631L16.1917 14.6938C17.1381 13.4629 17.7021 11.9385 17.7021 10.2814C17.7021 6.27617 14.3946 3 10.351 3C6.31705 3 3 6.2667 3 10.2814C3 14.2867 6.30749 17.5629 10.351 17.5629ZM10.351 15.9911C7.1965 15.9911 4.58683 13.4061 4.58683 10.2814C4.58683 7.15676 7.1965 4.5718 10.351 4.5718C13.5056 4.5718 16.1152 7.15676 16.1152 10.2814C16.1152 13.4061 13.5056 15.9911 10.351 15.9911Z"
        fill={color}
      />
    </svg>
  );
};
