import { createApi } from '@reduxjs/toolkit/query/react';
import { AxiosResponse } from 'axios';
import { axiosBaseQuery } from '@app/core/services/api';
import {
  AttractionCategoryCreateRequest,
  AttractionCategoryDeleteRequest,
  AttractionCategoryReadRequest,
  AttractionCategoryUpdateRequest,
  ListRequest,
} from '@app/core/types/requests';
import {
  AttractionCategoryCRUResponse,
  ListResponse,
} from '@app/core/types/responses';
import AttractionCategory, {
  AttractionCategoryExpand,
} from '@app/core/models/AttractionCategory';
import { API_REDUCERS_ENUM } from '@app/core/store/reducers';

export const attractionCategoriesApi = createApi({
  reducerPath: API_REDUCERS_ENUM.ATTRACTION_CATEGORIES,
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Attraction Categories'],
  endpoints: build => ({
    getAttractionCategoriesList: build.query<
      AxiosResponse<ListResponse<AttractionCategory>>,
      ListRequest<AttractionCategoryExpand>
    >({
      query: ({ expand, ...params }) => ({
        url: 'attraction_categories/',
        method: 'GET',
        params: { expand: expand?.join(','), ...params },
      }),
      transformResponse: (
        response: AxiosResponse<ListResponse<AttractionCategory>>,
      ) => {
        response.data.results = response.data.results.map(
          u => new AttractionCategory(u),
        );
        return response;
      },
      providesTags: res =>
        res?.data.results.length
          ? [
              ...res.data.results.map(
                ({ id }) => ({ type: 'Attraction Categories', id } as const),
              ),
            ]
          : [{ type: 'Attraction Categories', id: 'LIST' }],
    }),
    getAttractionCategory: build.query<
      AxiosResponse<AttractionCategoryCRUResponse>,
      AttractionCategoryReadRequest
    >({
      query: ({ id, expand, ...params }) => ({
        url: `attraction_categories/${id}/`,
        method: 'GET',
        params: { expand: expand?.join(','), ...params },
      }),
      transformResponse: (
        response: AxiosResponse<AttractionCategoryCRUResponse>,
      ) => {
        response.data = new AttractionCategory(response.data);
        return response;
      },
      providesTags: (_, __, { id }) => [{ type: 'Attraction Categories', id }],
    }),
    createAttractionCategory: build.mutation<
      AxiosResponse<AttractionCategoryCRUResponse>,
      AttractionCategoryCreateRequest
    >({
      query: data => ({
        url: 'attraction_categories/',
        method: 'POST',
        data,
      }),
      transformResponse: (
        response: AxiosResponse<AttractionCategoryCRUResponse>,
      ) => {
        response.data = new AttractionCategory(response.data);
        return response;
      },
      invalidatesTags: [{ type: 'Attraction Categories', id: 'LIST' }],
    }),
    updateAttractionCategory: build.mutation<
      AxiosResponse<AttractionCategoryCRUResponse>,
      AttractionCategoryUpdateRequest
    >({
      query: data => ({
        url: `attraction_categories/${data.id}/`,
        method: 'PATCH',
        data,
      }),
      onQueryStarted: async (requestBody, { dispatch, queryFulfilled }) => {
        const patchRes = dispatch(
          attractionCategoriesApi.util.updateQueryData(
            'getAttractionCategory',
            { id: requestBody.id },
            draft => {
              Object.assign(draft, requestBody);
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch {
          patchRes.undo();
        }
      },
      transformResponse: (
        response: AxiosResponse<AttractionCategoryCRUResponse>,
      ) => {
        response.data = new AttractionCategory(response.data);
        return response;
      },
      invalidatesTags: (_, __, { id }) => [
        { type: 'Attraction Categories', id },
      ],
    }),
    /**
     * @returns `response.status === 204` on success
     */
    deleteAttractionCategory: build.mutation<
      AxiosResponse,
      AttractionCategoryDeleteRequest
    >({
      query: data => ({
        url: `attraction_categories/${data.id}/`,
        method: 'DELETE',
        data,
      }),
      invalidatesTags: (_, __, { id }) => [
        { type: 'Attraction Categories', id },
      ],
    }),
  }),
});

export const {
  useGetAttractionCategoriesListQuery,
  useLazyGetAttractionCategoriesListQuery,
  useGetAttractionCategoryQuery,
  useLazyGetAttractionCategoryQuery,
  useCreateAttractionCategoryMutation,
  useDeleteAttractionCategoryMutation,
  useUpdateAttractionCategoryMutation,
} = attractionCategoriesApi;

export default attractionCategoriesApi;
