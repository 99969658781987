import { createApi } from '@reduxjs/toolkit/query/react';
import { AxiosResponse } from 'axios';
import { axiosBaseQuery } from '@app/core/services/api';
import {
  ListRequest,
  TagReadRequest,
  TagCreateRequest,
  TagUpdateRequest,
  TagDeleteRequest,
} from '@app/core/types/requests';
import { ListResponse, TagCRUResponse } from '@app/core/types/responses';
import { API_REDUCERS_ENUM } from '@app/core/store/reducers';
import Tag, { TagExpand } from '@app/core/models/Tag';

export const tagsApi = createApi({
  reducerPath: API_REDUCERS_ENUM.TAGS,
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Tags'],
  endpoints: build => ({
    getTagsList: build.query<
      AxiosResponse<ListResponse<Tag>>,
      ListRequest<TagExpand>
    >({
      query: ({ expand, ...params }) => ({
        url: 'tags/',
        method: 'GET',
        params: { expand: expand?.join(','), ...params },
      }),
      transformResponse: (response: AxiosResponse<ListResponse<Tag>>) => {
        response.data.results = response.data.results.map(u => new Tag(u));
        return response;
      },
      providesTags: res =>
        res?.data.results.length
          ? [
              ...res.data.results.map(
                ({ id }) => ({ type: 'Tags', id } as const),
              ),
            ]
          : [{ type: 'Tags', id: 'LIST' }],
    }),
    getTag: build.query<AxiosResponse<TagCRUResponse>, TagReadRequest>({
      query: ({ id, expand, ...params }) => ({
        url: `tags/${id}/`,
        method: 'GET',
        params: { expand: expand?.join(','), ...params },
      }),
      transformResponse: (response: AxiosResponse<TagCRUResponse>) => {
        response.data = new Tag(response.data);
        return response;
      },
      providesTags: (_, __, { id }) => [{ type: 'Tags', id }],
    }),
    createTag: build.mutation<AxiosResponse<TagCRUResponse>, TagCreateRequest>({
      query: data => ({
        url: 'tags/',
        method: 'POST',
        data,
      }),
      transformResponse: (response: AxiosResponse<TagCRUResponse>) => {
        response.data = new Tag(response.data);
        return response;
      },
      invalidatesTags: [{ type: 'Tags', id: 'LIST' }],
    }),
    updateTag: build.mutation<AxiosResponse<TagCRUResponse>, TagUpdateRequest>({
      query: data => ({
        url: `tags/${data.id}/`,
        method: 'PATCH',
        data,
      }),
      onQueryStarted: async (requestBody, { dispatch, queryFulfilled }) => {
        const patchRes = dispatch(
          tagsApi.util.updateQueryData(
            'getTag',
            { id: requestBody.id },
            draft => {
              Object.assign(draft, requestBody);
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch {
          patchRes.undo();
        }
      },
      transformResponse: (response: AxiosResponse<TagCRUResponse>) => {
        response.data = new Tag(response.data);
        return response;
      },
      invalidatesTags: (_, __, { id }) => [{ type: 'Tags', id }],
    }),
    /**
     * @returns `response.status === 204` on success
     */
    deleteTag: build.mutation<AxiosResponse, TagDeleteRequest>({
      query: data => ({
        url: `tags/${data.id}/`,
        method: 'DELETE',
        data,
      }),
      invalidatesTags: (_, __, { id }) => [{ type: 'Tags', id }],
    }),
  }),
});

export const {
  useCreateTagMutation,
  useDeleteTagMutation,
  useGetTagQuery,
  useGetTagsListQuery,
  useLazyGetTagQuery,
  useLazyGetTagsListQuery,
  useUpdateTagMutation,
} = tagsApi;

export default tagsApi;
