import React from 'react';

export const EmailIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({
  color = '#F5F6FA',
  ...props
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22 15.941C22 18.731 19.76 20.991 16.97 21.001H16.96H7.05C4.27 21.001 2 18.751 2 15.961V15.951C2 15.951 2.006 11.525 2.014 9.29898C2.015 8.88098 2.495 8.64698 2.822 8.90698C5.198 10.792 9.447 14.229 9.5 14.274C10.21 14.843 11.11 15.164 12.03 15.164C12.95 15.164 13.85 14.843 14.56 14.263C14.613 14.228 18.767 10.894 21.179 8.97798C21.507 8.71698 21.989 8.95098 21.99 9.36798C22 11.577 22 15.941 22 15.941Z"
        fill={color}
      />
      <path
        d="M21.4759 5.674C20.6099 4.042 18.9059 3 17.0299 3H7.04988C5.17388 3 3.46988 4.042 2.60388 5.674C2.40988 6.039 2.50188 6.494 2.82488 6.752L10.2499 12.691C10.7699 13.111 11.3999 13.32 12.0299 13.32C12.0339 13.32 12.0369 13.32 12.0399 13.32C12.0429 13.32 12.0469 13.32 12.0499 13.32C12.6799 13.32 13.3099 13.111 13.8299 12.691L21.2549 6.752C21.5779 6.494 21.6699 6.039 21.4759 5.674Z"
        fill={color}
      />
    </svg>
  );
};
