import React from 'react';

export const DifficultyIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({
  color = '#F5F6FA',
  ...props
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="24" height="24" fill="#E9B21A" />
      <path
        d="M15.4761 10.7542C15.4761 11.0304 15.6999 11.2542 15.9761 11.2542C16.2522 11.2542 16.4761 11.0304 16.4761 10.7542H15.4761ZM16.4761 2.0957C16.4761 1.81956 16.2522 1.5957 15.9761 1.5957C15.6999 1.5957 15.4761 1.81956 15.4761 2.0957H16.4761ZM16.4761 10.7542V2.0957H15.4761V10.7542H16.4761Z"
        fill={color}
      />
      <path
        d="M20.4567 2.50879H16.2261C16.088 2.50879 15.9761 2.62072 15.9761 2.75879V5.96958C15.9761 6.10765 16.088 6.21958 16.2261 6.21958H20.7794C20.9951 6.21958 21.1096 5.96471 20.9662 5.80349L19.8151 4.5084C19.7395 4.42344 19.7308 4.29822 19.7939 4.20363L20.6647 2.89746C20.7754 2.73133 20.6563 2.50879 20.4567 2.50879Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7121 19.9529L11.7779 21.3739C11.8251 21.4369 11.8992 21.4739 11.9779 21.4739H22.1422C22.3341 21.4739 22.4545 21.2665 22.3592 21.0999L18.9677 15.1647L16.3197 16.3682C16.1337 16.4527 15.92 16.4514 15.7351 16.3647L13.1473 15.1515L10.6923 19.6838C10.6458 19.7697 10.6535 19.8747 10.7121 19.9529ZM13.721 14.0923L16.0344 15.1769L18.3685 14.1161L16.1989 10.3193C16.1017 10.1492 15.8554 10.152 15.762 10.3243L13.721 14.0923Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.9008 16.7202L6.19617 14.9622C6.28076 14.8474 6.45336 14.8504 6.53389 14.9681L7.77376 16.7802L6.35495 17.326L4.9008 16.7202ZM4.29005 17.5491L1.64085 21.1444C1.54007 21.2812 1.63773 21.4744 1.80762 21.4744H10.5116C10.7127 21.4744 10.8315 21.2492 10.7179 21.0833L8.3541 17.6284L6.55803 18.3193C6.41817 18.3731 6.26295 18.371 6.12462 18.3133L4.29005 17.5491Z"
        fill={color}
      />
      <path
        d="M12.8882 9.19727L12.5302 6.78804L10.121 7.14602"
        stroke="#F5F6FA"
        strokeWidth="1.20257"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.90328 13.9927C1.693 14.2497 1.73088 14.6285 1.9879 14.8388C2.24491 15.0491 2.62374 15.0112 2.83402 14.7542L1.90328 13.9927ZM8.64152 12.0349L8.21635 12.46L8.64152 12.0349ZM6.09315 9.82125L6.55852 10.202L6.09315 9.82125ZM2.83402 14.7542L6.55852 10.202L5.62778 9.44049L1.90328 13.9927L2.83402 14.7542ZM5.98683 10.2305L8.21635 12.46L9.06669 11.6097L6.83717 9.38017L5.98683 10.2305ZM9.47318 12.3356L12.7721 7.38724L11.7715 6.72018L8.47258 11.6685L9.47318 12.3356ZM8.21635 12.46C8.58072 12.8244 9.18734 12.7643 9.47318 12.3356L8.47258 11.6685C8.6077 11.4658 8.89445 11.4375 9.06669 11.6097L8.21635 12.46ZM6.55852 10.202C6.41391 10.3788 6.14831 10.392 5.98683 10.2305L6.83717 9.38017C6.49557 9.03857 5.9337 9.06659 5.62778 9.44049L6.55852 10.202Z"
        fill={color}
      />
    </svg>
  );
};
