import React, { memo } from 'react';
import { FormControl, OutlinedInputProps } from '@mui/material';
import { Field, FieldProps } from 'formik';
import { TextField } from 'formik-mui';

interface Props {
  name: string;
  label?: string;
  required?: boolean;
  placeholder?: string;
  type?: React.InputHTMLAttributes<unknown>['type'];
  className?: string;
  multiline?: boolean;
  rows?: number;
  disabled?: boolean;
  size?: 'small' | 'medium';
  InputProps?: Partial<OutlinedInputProps>;
}

export const LocalInput: React.FC<Props> = memo(
  ({
    name,
    label,
    required,
    placeholder,
    type,
    className,
    multiline,
    rows,
    disabled,
    size,
    InputProps,
  }) => {
    return (
      <FormControl fullWidth className={className}>
        {label && <span className="text-xl mb-2.5">{label}</span>}
        <Field name={name}>
          {(props: FieldProps) => (
            <TextField
              fullWidth
              required={required}
              placeholder={placeholder}
              type={type}
              multiline={multiline}
              rows={rows}
              disabled={disabled}
              InputProps={InputProps}
              size={size}
              {...props}
            />
          )}
        </Field>
      </FormControl>
    );
  },
);
