import { BaseModel } from 'sjs-base-model';

export default class Creator extends BaseModel {
  public id: number = 0;
  public firstName: string = '';
  public lastName: string = '';
  public file: string | null = null;

  public get fullName(): string | undefined {
    if (!this.firstName && !this.lastName) return undefined;
    return [this.firstName, this.lastName]
      .filter(Boolean)
      .map(n => n[0].toUpperCase() + n.slice(1).toLowerCase())
      .join(' ');
  }

  public get initials() {
    if (!this.firstName && !this.lastName) return undefined;
    return [this.firstName, this.lastName]
      .filter(Boolean)
      .map(n => n[0].toUpperCase())
      .join(' ');
  }

  constructor(data: Partial<Creator>) {
    super({ expand: true });
    this.update(data);
  }
}
