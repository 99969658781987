import { BaseModel } from 'sjs-base-model';
import Creator from './Creator';

export enum CATEGORIES_ENUM {
  SIGHT = 'Достопримечательность',
  FOOD = 'Еда',
  HOTELS = 'Гостиницы',
  COFFEE = 'Кофейня',
  SPORT = 'Спорт',
  ENTERTAINMENT = 'Развлечения',
  BANK = 'Банк',
  TRANSPORT = 'Транспорт',
  MEDICINE = 'Медицина',
  PARKING = 'Парковки',
  MAIL = 'Почта',
  GOVERNMENT = 'Администрация',
  POLICE = 'Полиция',
  FIREFIGHTERS = 'Пожарная служба',
}

export const ENG_CATEGORY: Record<CATEGORIES_ENUM, string> = {
  [CATEGORIES_ENUM.SIGHT]: 'sight',
  [CATEGORIES_ENUM.FOOD]: 'food',
  [CATEGORIES_ENUM.HOTELS]: 'hotels',
  [CATEGORIES_ENUM.COFFEE]: 'coffee',
  [CATEGORIES_ENUM.SPORT]: 'sport',
  [CATEGORIES_ENUM.ENTERTAINMENT]: 'entertainment',
  [CATEGORIES_ENUM.BANK]: 'bank',
  [CATEGORIES_ENUM.TRANSPORT]: 'transport',
  [CATEGORIES_ENUM.MEDICINE]: 'medicine',
  [CATEGORIES_ENUM.PARKING]: 'parking',
  [CATEGORIES_ENUM.MAIL]: 'mail',
  [CATEGORIES_ENUM.GOVERNMENT]: 'government',
  [CATEGORIES_ENUM.POLICE]: 'police',
  [CATEGORIES_ENUM.FIREFIGHTERS]: 'firefighters',
};

export type CategoryExpand = Array<'attractions'>;

export default class Category extends BaseModel {
  public id: number = 0;
  public createdDttm: string = '';
  public createdBy: Creator = Creator as any;
  public name: CATEGORIES_ENUM = CATEGORIES_ENUM.ENTERTAINMENT;
  public logo: string | null = null;

  constructor(data: Partial<Category>) {
    super({ expand: true });
    this.update(data);
  }
}
